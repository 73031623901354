/* ============================================================================
  Breakpoint mixin
============================================================================== */

$min: min-width;
$max: max-width;

@mixin media-query($constraint_, $viewport1_, $viewport2_: null) {
  $constraint: $constraint_;
  $viewport1: $viewport1_;
  $viewport2: $viewport2_;
  @if type-of($constraint_) == number {
    $viewport1: $constraint_;
    $viewport2: $viewport1_;
    $constraint: null;
  }
  @if $constraint == $min {
    @media screen and ($min: $viewport1) {
      @content;
    }
  } @else if $constraint == $max {
    @media screen and ($max: $viewport1) {
      @content;
    }
  } @else {
    @media screen and ($min: $viewport1) and ($max: $viewport2) {
      @content;
    }
  }
}

/* ============================================================================
  Clearfix mixin
============================================================================== */

@mixin clearfix() {
  *zoom: 1;

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

/* ============================================================================
  Visually hidden mixin
============================================================================== */

@mixin visually-hidden() {
  position: absolute !important; // stylelint-disable-line
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
}

@mixin visually-shown($position: inherit) {
  position: $position !important; // stylelint-disable-line
  overflow: auto;
  width: auto;
  height: auto;
  margin: 0;
  clip: auto;
}
