@import "@styles/helpers/variables";

.btn {
  transition: background-color $transition-primary;
  user-select: none;
  cursor: pointer;
  display: inline-block;
  padding: 10px 30px 12px;
  width: auto;
  margin: 0;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  appearance: none;
  border: 2px solid transparent;
  border-radius: $border-radius;
  background-color: $color-btn-primary-background;
  color: $color-btn-primary-text;
  font-family: $neue-haas-unica-medium;
  font-size: 1.25rem;

  &:hover {
    background-color: $color-btn-primary-hover;
    color: $color-white;
  }
  &:active,
  &:focus {
    background-color: lighten($color-btn-primary-hover, 5);
    outline: 2px solid $color-btn-primary-background;
  }

  &.inverse {
    background-color: $color-btn-primary-text;
    color: $color-btn-primary-background;
    border: 2px solid $color-btn-primary-background;

    &:hover {
      background-color: $color-btn-primary-hover;
      color: $color-btn-primary-text;
      border: none;
    }
  }

  &.cancel {
    background-color: transparent;
    border-color: $color-secondary;
    color: $color-secondary;
  }

  &.warn {
    background-color: $color-red;
    &:hover,
    &:active,
    &:focus {
      background-color: darken($color-red, 15%);
    }
  }

  &.edit {
    background-color: white;
    border-color: $color-primary;
    color: $color-primary;
    display: flex;
    align-items: center;
    &::before {
      background-color: $color-primary;
      mask-image: url("/svgs/icon-pencil.svg");
      mask-repeat: no-repeat;
      mask-size: contain;
      height: 20px;
      width: 20px;
      margin-right: 0.5rem;
      display: block;
      content: "";
    }
  }

  &.login {
    background-color: transparent;
    padding: 0;
    margin: 0 auto 1rem;
    color: $color-btn-primary-background;
    font-size: 1rem;

    &:hover {
      color: $color-btn-primary-hover;
    }
  }

  &.checkoutEdit {
    font-family: $neue-haas-unica;
    font-size: 14px;
    background-color: transparent;
    color: $color-black;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      color: $color-black;
    }
  }

  &.icon {
    i {
      margin-left: 5px;
    }
  }

  &.iconLeft {
    i {
      margin-right: 5px;
    }
  }

  &[disabled],
  &.disabled {
    cursor: default;
    color: $color-white !important;
    background-color: $color-disabled !important;

    &:hover,
    &:active,
    &:focus {
      color: $color-white !important;
      background-color: $color-disabled !important;
    }
  }

  &.delete {
    background-color: transparent;
    border-color: transparent;
    padding: 0;
    margin: 0;
    border: none;
    &::before {
      height: 32px;
      width: 32px;
      text-align: center;
      font-family: $font-awesome;
      font-weight: normal;
      font-size: 1.5rem;
      color: $color-red;
      content: "\f00d";
    }

    &:hover,
    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }

  @media only screen and (max-width: $small) {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
  }
}
