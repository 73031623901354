@import "@styles/helpers/variables";
@import "@styles/helpers/mixins";
@import "@styles/components/homepage/homepage.module";
@import "@styles/components/elements/button.module.scss";

.sectionGuarantee {
  display: block;
  position: relative;

  .guaranteeArticle {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;

    z-index: 10;
    position: relative;
    display: grid;
    place-items: center;

    .guaranteeH2 {
      @extend .title;
    }

    .guaranteeP {
      font-size: 1rem;
    }

    .noRisk,
    .coupon {
      text-align: center;
      padding: 2rem;
      width: 100%;

      .iconWrapper {
        height: 180px;
        display: grid;
        place-items: center;
      }

      .guaranteeImg {
        height: 135px;
        width: auto;
      }
    }

    .noRisk {
      background-color: #f6f6ff;
      .guaranteeP {
        max-width: 480px;
        margin: 0 auto 2rem;
      }
    }

    .successMessage,
    .errorMessage {
      display: none;
    }
  }

  @include media-query($min, $pre-large) {
    &::after {
      content: "";
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      height: 100%;
      display: block;
      background-color: #f6f6ff;
    }

    .noRisk,
    .coupon {
      padding: 0;
    }

    .noRisk {
      .guaranteeP {
        max-width: 600px;
      }
    }

    .guaranteeArticle {
      padding: 2rem 0 3rem;
      z-index: 10;
      position: relative;
      display: grid;
      grid-template-columns: 50% auto;
      place-items: initial;
    }
  }
}

/* ============================================================================
  Guarantee
============================================================================== */

.guarantee {
  position: relative;
  background-color: $color-white;

  &::after {
    @include media-query($min, $medium) {
      content: "";
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      height: 100%;
      display: block;
    }
  }
}

.guaranteeContent {
  margin: 0 auto;
  padding: 62px 0;

  @include media-query($min, $medium) {
    max-width: 480px;
  }

  @include media-query($min, $large) {
    padding: 50px 0 100px;
  }

  &.first {
    @include media-query($max, $pre-medium) {
      width: calc(100% + 64px);
      margin-left: -32px;
      background-color: $color-background-green;
      padding: 62px 32px;
    }
  }

  .responsive-image__wrapper {
    @include media-query($min, $medium) {
      min-height: 157px;
    }
  }

  h3 {
    font-size: calc-rem(32px);
    line-height: calc-rem(37px);
    margin: 30px 0 16px;

    @include media-query($min, $large) {
      font-size: calc-rem(53px);
      line-height: calc-rem(61px);
      margin: 13px 0 16px;
    }
  }

  .guaranteeP {
    margin-bottom: 30px;

    @include media-query($min, $large) {
      margin-bottom: 55px;
    }
  }
}

.newsletterForm {
  ::placeholder {
    color: #8d8f99;
  }

  .formInput {
    height: 67px;
    background-color: $color-grey;
    border: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 1rem;
    padding: 10px 33px;

    @media screen and (max-width: $xsmall) {
      font-size: 0.7rem;
    }
  }
  .successMessage,
  .errorMessage {
    display: none;
  }
}

.guaranteeBtn {
  @extend .btn;
}

.guaranteeIcon {
  margin-left: 10px;
}

.newsletterForm .guaranteeBtn {
  height: 67px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 10px 24px 15px 31px;
}

.success-message {
  a {
    font-size: 115%;
    font-weight: bold;
    text-decoration: underline;
    color: #6965f8;
  }
}
