@import "@styles/helpers/variables";
@import "@styles/helpers/mixins";
@import "@styles/helpers/functions";

.heroWrapper {
  background-color: white;
}
.title {
  font-size: calc-rem(50px);
  text-align: center;
  margin: 0 0 1.5rem;
  line-height: 0.85;
}

.titleH3 {
  font-size: calc-rem(28px);
  text-align: center;
}

.article {
  max-width: 100vw;
  margin: 0 auto;
  padding: 2rem $gutter;
  display: grid;
  place-items: center;
  position: relative;

  h2 {
    @extend .title;
  }

  h3 {
    @extend .titleH3;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;

    @media screen and (max-width: $xsmall) {
      font-size: 0.79rem;
    }
  }
}

.section {
  width: 100%;
  display: grid;
}

.spacer {
  display: none;
  margin: 0 auto;
  padding: 1rem 0;
  background-color: $color-light-grey;
  color: $color-deep-purple;

  div {
    width: fit-content;
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto 1fr auto;
  }

  span {
    display: grid;
    place-items: center;
    font-size: 2rem;
    margin: 0 1rem;
    min-height: 65px;

    &:first-child {
      &::after {
        display: inline-block;
        font-size: 2rem;
        font-family: $font-awesome;
        font-weight: 900;
        content: "\f3e5";
        transform: rotate(-90deg);
      }
    }

    &:last-child {
      &::after {
        display: inline-block;
        font-size: 2rem;
        font-family: $font-awesome;
        font-weight: 900;
        content: "\f3e5";
        transform: rotate(90deg);
      }
    }
  }

  h2 {
    display: grid;
    place-items: center;
    line-height: 0.85;
    text-align: center;
    width: fit-content;
    margin: 0;
  }
}

@include media-query($min, $medium) {
  .spacer {
    display: flex;
    justify-content: center;
  }
  .article {
    max-width: 100%;
    padding: 3rem 32px;

    h3 {
      font-size: calc-rem(28px);
    }
  }
}

@media only screen and (min-width: $large) {
  .article {
    max-width: $site-width;
    min-width: 1068px;
    h3 {
      font-size: calc-rem(35px);
    }

    p {
      font-size: calc-rem(18px);
    }
  }
}

.howItWorks {
  display: block;
  text-align: center;
  background-color: $color-white;
  margin: auto;
  width: 100%;

  p strong {
    background: $highlight;
  }

  @include media-query($min, $large) {
    position: relative;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $color-white;
      opacity: 0.6;
      content: "";
      z-index: -1;
    }

    article > p {
      max-width: 80%;
      margin: auto;
    }
  }
}

.testimonialWrapper {
  padding: 2rem 0;
  background-color: $color-white;
  text-align: center;
}
