$img_base: "/apps/amp-dev/";

/* ============================================================================
  Breakpoint and grid variables
============================================================================== */

$all: 1px;
$xxsmall: 320px;
$xsmall: 375px;
$smaller: 425px;
$small: 550px;
$medium: 768px;
$large: 1025px;
$larger: 1200px;
$xlarge: 1400px;
$xxlarge: 1440px;
$xxxlarge: 1650px;
$jumbo: 2150px;

$viewport-increment: 1px;
$pre-xsmall: $xsmall - $viewport-increment;
$pre-smaller: $smaller - $viewport-increment;
$pre-small: $small - $viewport-increment;
$pre-medium: $medium - $viewport-increment;
$pre-large: $large - $viewport-increment;
$pre-larger: $larger - $viewport-increment;
$pre-xlarge: $xlarge - $viewport-increment;
$pre-xxlarge: $xxlarge - $viewport-increment;
$pre-xxxlarge: $xxxlarge - $viewport-increment;
$pre-jumbo: $jumbo - $viewport-increment;

$breakpoints: (
  "all" "(min-width: #{$all})",
  "small" "(max-width: #{$pre-medium})",
  "medium" "(min-width: #{$medium}) and (max-width: #{$pre-large})",
  "medium-down" "(max-width: #{$pre-large})",
  "medium-up" "(min-width: #{$medium})",
  "large" "(min-width: #{$large})",
  "xlarge" "(min-width: #{$xlarge})"
);

$breakpoint-has-widths: (
  "all",
  "small",
  "medium",
  "medium-down",
  "medium-up",
  "large",
  "xlarge"
);
$breakpoint-has-push: ("medium", "medium-up", "large", "xlarge");

$content-width: 1086px;
$site-width: 1313px;
$gutter: 2rem;
$border-radius: 60px;

/* ============================================================================
  Color variables
============================================================================== */

// Primary colors
$ada-compliant-priarmy: #00c9d0;
$color-primary: #00cacf;
$color-nav-green: #d0f5f6;
$color-background-green: #e6fafa;
$color-background-lt-green: #f4fdfd;
$color-background-lt-purple: #f6f6ff;
$color-secondary: #25273c;

// General colors
$color-faint-blue: #f4fdfd;
$color-happy-green: #63d668;
$color-bright-green: #48bf29;
$color-dark-green: #155724;
$color-light-green: #d4edda;
$color-light-red: #ffd7d6;
$color-light-purple: #f3f2fe;
$color-grey: #f6f6f6;
$color-light-grey: #f9f9fd;
$color-med-grey: #c6c6c5;
$color-dark-grey: #8b8b8b;
$highlight: #ffffcc;
$color-yellow: #f4e04d;
$color-white: #ffffff;
$color-black: #25283d;
$color-purple: #6965f8;
$color-deep-purple: #4b40c9;
$color-dark-yellow: #c0ab0c;

// price colors
$color-red: #ff4242;
$color-red-light: #ffd0d0;
$price-dark-grey: #5f5f5f;

// Button colors
$color-btn-primary-background: $color-deep-purple;
$color-btn-primary-hover: $color-purple;
$color-btn-primary-text: $color-white;
$color-btn-autoship-background: $color-yellow;
$color-btn-autoship-hover: $color-dark-yellow;
$color-btn-autoship-text: $color-black;

// Body text color
$color-body-text: #25283d;

// Site overlay
$color-site-overlay: rgba(0, 0, 0, 0.2);

// Border colors
$color-border: #e1e1e1;

// Helper colors
$color-disabled: #acbbc1;

.primary {
  color: $color-primary;
}
.secondary {
  color: $color-purple;
}
.grey {
  color: $color-dark-grey;
}
.error {
  color: $color-red;
  font-weight: 900;
}
.gold {
  color: $color-yellow;
}
.bg-lt-green {
  background-color: $color-background-lt-green;
}
.bg-lt-purple {
  background-color: $color-background-lt-purple;
}
.highlight {
  background-color: $highlight;
}

/* ============================================================================
  Transition variables
============================================================================== */

$transition-primary: 0.2s cubic-bezier(0.46, 0.01, 0.32, 1);
$transition-ajaxcart: 0.3s cubic-bezier(0.46, 0.01, 0.32, 1);

/* ============================================================================
  Font variables
============================================================================== */

$serenity: "serenity", helvetica, arial, sans-serif;
$neue-haas-unica-medium: "neue-haas-unica-medium", helvetica, arial, sans-serif;
$neue-haas-unica: "neue-haas-unica", helvetica, arial, sans-serif;
$font-awesome: "Font Awesome 5 Pro";
